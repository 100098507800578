import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'

import GenericPageContainer from './GenericPageContainer'
import { withRouter } from 'next/router'


export default compose(
  withRouter,
  connect(state => ({
    seoTemplates: get(state, 'cmsInfo.data.seo_templates', {}),
    homeSlugs: get(state, 'cmsInfo.data.home_page_slugs', {}),
  }))
)(GenericPageContainer)
