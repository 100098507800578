import { Component } from 'react'
import template from 'lodash/template'
import PropTypes from 'prop-types'

import GenericPageView from './GenericPageView'
import get from 'lodash/get'
import parseTemplateData from 'common/utils/parseTemplateData'


const propTypes = {
  data: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    locale: PropTypes.string,
  }).isRequired,
  homeSlugs: PropTypes.shape({
    de: PropTypes.string,
    fr: PropTypes.string,
    default: PropTypes.string,
  }).isRequired,
  seoTemplates: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
}

const defaultProps = {
  isPreview: false,
}

export default class GenericPageContainer extends Component {
  render() {
    const { seoTemplates, router, data } = this.props
    const seoContext = get(data, 'meta.seo_context', {})
    const title = template(parseTemplateData(get(seoTemplates[get(data, 'meta.type')], `title_${router.locale}`, ''), seoContext))(seoContext)
    const description = template(parseTemplateData(get(seoTemplates[get(data, 'meta.type')], `description_${router.locale}`, ''), seoContext))(seoContext)

    return (
      <GenericPageView
        data={data}
        title={title}
        description={description}
        seoContext={seoContext}
        languageCode={router.locale}
        isPreview={this.props.isPreview}
      />
    )
  }
}

GenericPageContainer.propTypes = propTypes
GenericPageContainer.defaultProps = defaultProps
