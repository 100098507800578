import API from 'api'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import get from 'lodash/get'
import { useRouter } from 'next/router'

import AppLayout from 'layouts/AppLayout'
import GenericPage from 'components/genericPage/GenericPage'
import globalRequest from 'common/utils/globalRequest'
import getCampaignBanner from 'common/utils/getCampaignBanner'
import callPromiseWithRetry from 'common/utils/callPromisewithRetry'


export async function getStaticProps({ locale }) {
  const requiredLocale = process.env.EXCLUDE_LOCALES.includes(locale) ? 'de' : locale
  const cmsInfoTranslations = await globalRequest(requiredLocale)
  const homePageSlugs = get(cmsInfoTranslations, 'cmsInfo.data.home_page_slugs', {})
  const callPageData = () => API.get(`api/v1/pages/${homePageSlugs[requiredLocale]}`, { headers: { 'Accept-Language': requiredLocale } })
  const pageData = await callPromiseWithRetry(callPageData)

  if(!cmsInfoTranslations?.cmsInfo?.data) {
    throw new Error('Cms-info data not received')
  }
  if(!cmsInfoTranslations?.translations?.data) {
    throw new Error('Translations data not received')
  }
  if(!pageData) {
    throw new Error('Home Page data not received')
  }
  const experienceMasonry = pageData.content.find(i => i.type === 'experience_masonry_grid')
  let getExperiences = null
  if(experienceMasonry) {
    getExperiences = await API.get('api/v1/feefo/experiences', { headers: { 'Accept-Language': requiredLocale }, params: { kind: experienceMasonry.value.kind, parent_id: experienceMasonry.value.parent_id, offset: 0, limit: 3 } })
      .then(res => ({ getExperiences: { data: res } }))
      .catch(() => null)
  }
  const campaignBanner = getCampaignBanner({ slug: homePageSlugs[requiredLocale], homeSlug: homePageSlugs[requiredLocale], banners: get(cmsInfoTranslations, 'cmsInfo.data.campaign_banners') })
  return { props: { initialReduxStore: { ...cmsInfoTranslations, ...getExperiences, ...campaignBanner }, pageData }, revalidate: process.env.REVALIDATE_HOME_PAGE }
}

HomePageComponent.propTypes = {
  pageData: PropTypes.object.isRequired,
}

export default function HomePageComponent({ pageData }) {
  const { locale } = useRouter()
  const isHeaderFooterEnabled = useMemo(() => ({
    isHeaderEnabled: get(pageData, 'display_header', true),
    isFooterEnabled: get(pageData, 'display_footer', true),
  }), [pageData])
  let data = {
    ...pageData,
    meta: {
      ...pageData.meta,
      canonical_url: locale !== 'fr' ? '/' : pageData.meta.canonical_url,
      hreflang_data: {
        de: '/',
        default: '/',
        fr: '/fr',
      },
    },
  }
  return (
    <AppLayout withCover {...isHeaderFooterEnabled}>
      <GenericPage data={data} />
    </AppLayout>
  )
}
